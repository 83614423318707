<template>
  <div id="incidencias" class="contenedor">
    <loader :show="show" />
    <sesion :expiration="expiration" />
    <Sidebar></Sidebar>
    <v-container fluid class="base text-center">
      <v-row no-gutters class="titulo ml-10 mt-2 mb-n5"
        >LISTA DE INCIDENCIAS</v-row
      >
      <v-row no-gutters justify="end" class="mr-12 mb-4">
        <v-col
          cols="12"
          lg="6"
          md="5"
          sm="4"
          xs="12"
          class="selectEmpresa"
          align-self="end"
        >
          <v-row class="ml-12">
            <label for="enterprise" class="ml-5 mr-2">Empresa Pagadora</label>
            <select
              name="enterprise"
              id="enterprise"
              v-model="enterprise"
              @change="obtenerEmpresa()"
            >
              <option selected disabled value="0">Selecciona Empresa</option>
              <option
                v-for="enterprise in enterprises"
                :key="enterprise.id"
                v-bind:value="enterprise.id"
              >
                {{ enterprise.razonSocial }}
              </option>
            </select>
          </v-row>
        </v-col>
        <v-col cols="12" lg="6" md="6" sm="5" xs="12">
          <v-card-title>
            <router-link to="/addIncidence" id="nuevaIncidencia">
              <button class="breakSearchE botonAmarillo">
                Alta de Incidencia
              </button>
            </router-link>
            <v-text-field
              class="mr-2"
              v-model="search"
              append-icon="search"
              label="Buscador"
              single-line
              hide-details
            ></v-text-field>
          </v-card-title>
        </v-col>
      </v-row>
      <v-row no-gutters class="datos pa-0" style="height: auto !important">
        <v-card style="width: 100%; background: rgb(199, 195, 199) !important">
          <v-data-table
            :headers="headers"
            :items="incidences"
            :search="search"
            class="elevation-2 text--dark"
            loading-text="Cargando datos... Por favor espere"
            :items-per-page="5"
            :no-results-text="'No se encontraron incidencias'"
            :no-data-text="'No hay datos'"
            :footer-props="{
              'items-per-page-text': 'Incidencias por página',
              'items-per-page': [5, 10, 15],
              'items-per-page-all-text': 'Todos',
              'items-per-page-options': [5],
              'loading-text': 'Obteniendo datos... Por favor espere',
              'no-data-text': 'No hay datos...',
              'no-results-text': 'No se encontraron incidencias',
            }"
          >
            <template v-slot:no-data>
              <v-alert :value="true" color="#ffffff" icon="warning">
                <p style="color: black">No hay datos en sistema ...</p>
              </v-alert>
            </template>
            <template v-slot:body="{ items }">
              <tbody>
                <tr
                  v-for="item in items"
                  :key="item.id"
                  :class="{ selectedRow: item === selectedItem }"
                >
                  <td class="text-sm-center">{{ item.id }}</td>
                  <td class="text-sm-center">
                    {{
                      item.empleado.nombre +
                      " " +
                      item.empleado.apellidoPaterno +
                      " " +
                      item.empleado.apellidoMaterno
                    }}
                  </td>
                  <td class="text-sm-center">
                    {{ item.fechaAusentismoInicio.substr(0, 10) }}
                  </td>
                  <td class="text-sm-center">
                    {{ item.fechaReincorporacion.substr(0, 10) }}
                  </td>
                  <td class="text-sm-center">
                    {{ item.viaComunicacion.viaComunicacion }}
                  </td>
                  <td class="text-sm-center">{{ item.comentario }}</td>
                  <td class="text-sm-center">
                    {{ item.causaAusentismo.causa }}
                  </td>
                  <td class="text-sm-center">{{ item.estatus.estatus }}</td>
                  <td
                    class="text-sm-center"
                    v-if="item.estatus.estatus != 'Por calificar'"
                  >
                    {{ item.calificacion.aplicacion }}
                  </td>
                  <td
                    class="text-sm-center"
                    v-if="item.estatus.estatus === 'Por calificar'"
                  >
                    {{ item.estatus.estatus }}
                  </td>
                  <td class="text-sm-center" v-if="item.documentos.length < 3">
                    <v-col
                      v-if="item.documentos.length == 1"
                      cols="12"
                      sm="12"
                      md="12"
                      lg="12"
                    >
                      <v-img
                        max-height="125"
                        max-width="125"
                        :src="
                          item.documentos[0].empleadoDocumentos.urlDocumento
                        "
                        @click="
                          abrirDocumento(
                            item.documentos[0].empleadoDocumentos.urlDocumento
                          )
                        "
                      ></v-img>
                    </v-col>
                    <v-row v-else>
                      <v-col
                        cols="12"
                        sm="6"
                        md="6"
                        lg="6"
                        v-for="foto in item.documentos"
                        :key="foto.empleadoDocumentosId"
                      >
                        <v-img
                          max-height="125"
                          max-width="125"
                          :src="foto.empleadoDocumentos.urlDocumento"
                          @click="
                            abrirDocumento(foto.empleadoDocumentos.urlDocumento)
                          "
                        ></v-img> </v-col
                    ></v-row>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-data-table>
        </v-card>
      </v-row>
      <v-row
        no-gutters
        class="titulo ml-10"
        justify="center"
        v-if="rolePersona == 'Admin'"
      >
        <v-col cols="12" lg="6" md="6" sm="6" class="mt-2">
          <button
            class="botonAmarillo mt-5"
            style="font-size: 0.9rem"
            @click="
              edit = true;
              message = 'Descargar Reporte';
              descarga = true;
            "
          >
            Descargar excel
          </button>
          <button
            class="botonAmarilloDerecho"
            style="font-size: 0.9rem"
            @click="
              edit = true;
              message = 'Enviar a nómina';
              enviar = true;
            "
          >
            Enviar a Nómina
          </button>
        </v-col>
      </v-row>
    </v-container>
    <!--Confirmación de datos-->
    <v-dialog v-model="confirmation" width="400">
      <v-card>
        <v-card-title class="orange white--text">
          <span class="headline">Reporte de Incidencias</span>
        </v-card-title>
        <v-card-text>
          <br />
          <h3 class="text-lg-center text-sm-center text-md-center">
            {{ respuesta }}
          </h3>
          <br />
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="success" text @click="confirmation = false">OK</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!--Editar item-->
    <v-dialog v-model="edit" width="350" v-if="incidences.length > 0">
      <v-card>
        <v-card-title class="orange white--text">
          <span class="headline">{{ message }}</span>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12" lg="5" md="5" sm="5" class="form-group">
              <label for="fechaInicio">Fecha de Inicio</label></v-col
            >
            <v-col cols="12" lg="7" md="7" sm="7" class="form-group">
              <input
                name="fechaInicio"
                id="fechaInicio"
                v-model="fechaInicio"
                class="inputs"
                type="date"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" lg="5" md="5" sm="5" class="form-group">
              <label for="fechaFin">Fecha de Fin</label>
            </v-col>
            <v-col cols="12" lg="7" md="7" sm="7" class="form-group">
              <input
                name="fechaFin"
                id="fechaFin"
                v-model="fechaFin"
                class="inputs"
                type="date"
              />
            </v-col>
            <span v-if="errorFecha" style="color: red"
              >*La fecha de inicio no puede ser superior a la fecha de fin del
              reporte.</span
            >
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn color="red" text @click="auxiliar()">Cancelar</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="success" text @click="validacion()">Aceptar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!--DOCUMENTO-->
    <v-dialog v-model="documento" width="400" @click="active = true">
      <v-card>
        <v-card-title class="orange white--text">
          <span class="headline">Documento presentado</span>
        </v-card-title>
        <v-card-text class="mt-4">
          <v-img :src="url"></v-img>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="success" text :href="url" download>Descargar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import Sidebar from "@/components/Sidebar.vue";
import Loader from "@/components/Loader";
import Sesion from "@/components/Sesion";
export default {
  components: {
    Sidebar,
    Loader,
    Sesion,
  },
  data() {
    return {
      show: false,
      message: "",
      active: false,
      expiration: false,
      search: "",
      dialog: false,
      selectedItem: null,
      headers: [
        {
          text: "Id",
          align: "center",
          value: "id",
          sortable: false,
        },
        {
          text: "Nombre Empleado",
          align: "center",
          value: "empleado.nombre",
        },
        {
          text: "Fecha Ausentismo",
          value: "fechaAusentismoInicio",
          align: "center"
        },
        {
          text: "Fecha Reincorporación",
          value: "fechaReincorporacion",
          align: "center",
        },
        {
          text: "Vía de Comunicación",
          value: "viaComunicacion.viaComunicacion",
          align: "center",
        },
        {
          text: "Comentario",
          value: "comentario",
          align: "center",
          sortable: false,
        },
        {
          text: "Causa",
          value: "causaAusentismo.causa",
          align: "center",
        },
        {
          text: "Estatus",
          value: "estatus.estatus",
          align: "center",
        },
        {
          text: "Calificación",
          align: "center",
          value:"calificacion.aplicacion"
        },
        {
          text: "Documentos",
          value: "actions",
          align: "center",
          sortable: false,
        },
      ],
      enviar: false,
      descarga: false,
      incidences: [],
      enterprise: localStorage.empresaIdGlobal,
      enterprises: [],
      respuesta: "",
      advice: false,
      id: "",
      confirmation: false,
      edit: false,
      vacio: false,
      url: "",
      documento: false,
      persona: localStorage.empleadoIdGlobal,
      person: localStorage.nombreEmpleadoGlobal,
      aplicaciones: [],
      fechaInicio: "",
      fechaFin: "",
      errorFecha: false,
      rolePersona: localStorage.roles,
    };
  },
  methods: {
    alfaNumerico: function (evt) {
      var regex = new RegExp("^[a-zA-ZÀ-ÿ\u00f1\u00d10-9 ]+$"); // Letras,espacio y números
      var str = String.fromCharCode(!evt.charCode ? evt.which : evt.charCode);
      if (regex.test(str)) {
        return true;
      }
      evt.preventDefault();
      return false;
    },
    auxiliar() {
      this.fechaFin = "";
      this.fechaInicio = "";
      this.edit = false;
      this.errorFecha = false;
      this.enviar = false;
      this.descarga = false;
    },
    onClickOutside() {
      this.active = false;
    },
    validacion() {
      this.errorFecha = false;
      if (this.fechaInicio > this.fechaFin) {
        this.errorFecha = true;
      } else {
        if (this.descarga == true) {
          this.descargarExcel();
          return;
        }
        if (this.enviar == true) {
          this.enviarNomina();
          return;
        }
      }
    },
    abrirDocumento(url) {
      this.url = url;
      this.documento = true;
    },
    enviarNomina() {
      this.edit = false;
      this.show = true;
      if (this.incidences.length > 0) {
        axios
          .post(
            Server + "/ausentismosLaborales/reporte-excel-nominpaq",
            {
              EmpresaId: this.enterprise,
              AreaId: 0,
              SucursalId: 0,
              FechaInicio: this.fechaInicio,
              FechaFin: this.fechaFin,
            },
            {
              responseType: "blob",
            },
            {
              headers: {
                Authorization: localStorage.token,
              },
            }
          )
          .then((response) => {

            //console.log(response);
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement("a");
            link.href = url;
            var fecha = new Date();
            link.setAttribute(
              "download",
              "Incidencias_" +
                fecha.getDate() +
                fecha.getMonth() +
                fecha.getFullYear() +
                ".xlsx"
            );
            document.body.appendChild(link);
            link.click();
            this.fechaInicio = "";
            this.fechaFin = "";
            this.show = false;
            this.enviar = false;
            this.respuesta = "Las incidencias fueron enviadas exitosamente.";
            this.confirmation = true;
          })
          .catch((e) => {
            //console.log(e);
            this.fechaInicio = "";
            this.fechaFin = "";
            this.show = false;
            this.enviar = false;
            this.respuesta = "El reporte no pudo enviarse exitosamente.";
            this.confirmation = true;
            if (e.response.status == 401) {
              this.expiration = true;
            }
          });
      } else {
        this.fechaInicio = "";
        this.fechaFin = "";
        this.enviar = false;
        this.show = false;
      }
    },
    descargarExcel() {
      this.edit = false;
      this.show = true;
      if (this.incidences.length > 0) {
        axios
          .post(
            Server + "/ausentismosLaborales/reporte-excel",
            {
              EmpresaId: this.enterprise,
              AreaId: 0,
              SucursalId: 0,
              FechaInicio: this.fechaInicio,
              FechaFin: this.fechaFin,
            },
            {
              responseType: "blob",
            },
            {
              headers: {
                Authorization: localStorage.token,
              },
            }
          )
          .then((response) => {
            //console.log(response);
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement("a");
            link.href = url;
            var fecha = new Date();
            link.setAttribute(
              "download",
              "Incidencias_" +
                fecha.getDate() +
                fecha.getMonth() +
                fecha.getFullYear() +
                ".xlsx"
            );
            document.body.appendChild(link);
            link.click();
            this.fechaInicio = "";
            this.fechaFin = "";
            this.descarga = false;
            this.show = false;
            this.respuesta = "El reporte fue generado exitosamente.";
            this.confirmation = true;
          })
          .catch((e) => {
            //console.log(e);
            this.fechaInicio = "";
            this.fechaFin = "";
            this.show = false;
            this.respuesta = "El reporte no pudo generarse exitosamente.";
            this.confirmation = true;
            if (e.response.status == 401) {
              this.expiration = true;
            }
          });
      } else {
        this.show = false;
        this.fechaInicio = "";
        this.fechaFin = "";
        this.descarga = false;
      }
    },
    listar() {
      this.show = true;
      axios
        .get(Server + "/empresas", {
          headers: {
            Authorization: localStorage.token,
          },
        })
        .then((response) => {
          this.enterprises = response.data;
          this.show = false;
        })
        .catch((e) => {
          console.log(e);
          this.show = false;
          if (e.response.status == 401) {
            this.expiration = true;
          }
        });
      this.ausentismoAplicaciones();
    },
    ausentismoAplicaciones() {
      this.show = true;
      axios
        .get(Server + "/ausentismosLaborales/catalogo-aplicaciones", {
          headers: {
            Authorization: localStorage.token,
          },
        })
        .then((response) => {
          this.aplicaciones = response.data;
          this.show = false;
        })
        .catch((e) => {
          console.log(e);
          this.show = false;
          if (e.response.status == 401) {
            this.expiration = true;
          }
        });
    },
    obtenerEmpresa() {
      this.incidences = [];
      this.show = true;
      var urlCompleta = "/ausentismosLaborales/por-empresa/" + this.enterprise;
      urlCompleta += "?empleadoId="+this.persona;
      urlCompleta += "&role="+this.rolePersona;
      axios
        .get(Server + urlCompleta, {
          headers: {
            Authorization: localStorage.token,
          },
        })
        .then((response) => {
          response.data.forEach((incidencia, index) => {
            if (incidencia.calificacionId == null) {
              //console.log("no tiene calificacion");
              this.incidences.push(incidencia);
            } else {
              //console.log("cumplio con calificacion");
              var aux = this.aplicaciones.find(
                (app) => app.id === incidencia.calificacionId
              );
              this.incidences.push({
                viaComunicacionId: incidencia.viaComunicacionId,
                viaComunicacion: incidencia.viaComunicacion,
                id: incidencia.id,
                fechaReincorporacion: incidencia.fechaReincorporacion,
                fechaAusentismoInicio: incidencia.fechaAusentismoInicio,
                estatusId: incidencia.estatusId,
                estatus: {
                  estatus: incidencia.estatus.estatus,
                  id: incidencia.estatus.id,
                },
                calificacionId: incidencia.calificacionId,
                causaAusentismo: incidencia.causaAusentismo,
                causaAusentismoId: incidencia.causaAusentismoId,
                comentario: incidencia.comentario,
                documentos: incidencia.documentos,
                empleado: incidencia.empleado,
                empleadoId: incidencia.empleadoId,
                calificacion: {
                  aplicacion: aux.aplicacion,
                  aplicacionId: incidencia.calificacion.aplicacionId,
                  comentario: incidencia.calificacion.comentario,
                  empleadoCalifica: incidencia.calificacion.empleadoCalifica,
                  empleadoCalificaId:
                    incidencia.calificacion.empleadoCalificaId,
                  id: incidencia.calificacion.id,
                },
              });
            }
          });
          //console.log("armado",this.incidences);
          this.show = false;
        })
        .catch((e) => {
          console.log(e);
          this.show = false;
          if (e.response.status == 401) {
            this.expiration = true;
          }
        });
    },
  },
  created() {
    //console.log("creado lista de area" + localStorage.idEmpresa);
    this.listar();
    this.obtenerEmpresa();
  },
};
</script>